import React from "react";

import { graphql } from "gatsby";

import styled from "@emotion/styled";

import CalculatorPage from "../../../../components/pages/HeatingOil/CalculatorPage";
import { takeWhile } from "../../utils";

//Add some bottom padding because of the missing Footer:
const Container = styled.div`
    padding-bottom: 5em;
`;

const HeizoelWebview = ({
    data: {
        page: { elements, ...page },
    },
}) => {
    return (
        <Container>
            <CalculatorPage
                isWebview={true}
                pageData={{
                    ...page,
                    elements: takeWhile(
                        (a) => a.type !== "calculatorWithOutput",
                        elements
                    ),
                }}
            />
        </Container>
    );
};

export default HeizoelWebview;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "heizoel" }) {
            ...Page
            disabled
            elements {
                ...EntireElement
            }
        }
    }
`;
